/* eslint-disable react/jsx-no-target-blank */
import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

const styles = makeStyles((theme) => ({
  topBarRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.text.primary}`,
    paddingBottom: 10,
    marginBottom: 30,
  },
  title: {
    fontSize: '2.125rem',
    fontWeight: 400,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.825rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.525rem',
    },
  },
  powered: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  provided: {
    marginBottom: 10,
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
    },
  },
  image: {
    [theme.breakpoints.down('sm')]: {
      width: 100,
    },
    [theme.breakpoints.down('xs')]: {
      width: 80,
    },
  },
}));

const ElectionTopBar = ({ title, lastUpdate = null }) => {
  const classes = styles();

  const getDate = (date) => {
    if (!date) return null;
    return format(new Date(`${date} 00:00:00`), 'MM/dd/Y');
  };

  return (
    <Box className={classes.topBarRoot}>
      <Box>
        <Box className={classes.title}>{title}</Box>
        {lastUpdate && (
          <Box className={classes.lastUpdate}>Last Update: {getDate(lastUpdate)}</Box>
        )}
      </Box>
      <Box className={classes.powered}>
        <Typography className={classes.provided}>Provided By:</Typography>
        <a href="https://www.cookpolitical.com/" target="_blank">
          <img
            className={classes.image}
            src="/images/election-provided-by.png"
            alt="Provided By"
          />
        </a>
      </Box>
    </Box>
  );
};

ElectionTopBar.propTypes = {
  title: PropTypes.string.isRequired,
  lastUpdate: PropTypes.string,
};

export default ElectionTopBar;
