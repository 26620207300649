/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import AllPublishers from '../../containers/AllPublishers';
import AppRedirect from '../../containers/AppRedirect/AppRedirect';
import Article, { SharedArticle } from '../../containers/Article';
import Bookmarks from '../../containers/Bookmarks';
import Channel from '../../containers/Channel';
import ChannelAbout from '../../containers/Channel/ChannelAbout';
import ChannelCategories from '../../containers/Channel/ChannelCategories';
import ChannelRecent from '../../containers/Channel/ChannelRecent';
import ChannelMembers from '../../containers/Channel/ChannelMembers';
import ContestInternal from '../../containers/Contest/ContestInternal';
import Contests from '../../containers/Contest/Contests';
import ContestsWebViewToken from '../../containers/Contest/ContestsWebViewToken';
import ContestsWebView from '../../containers/Contest/ContestsWebView';
import ContestInternalWebView from '../../containers/Contest/ContestsInternalWebView';
import DeleteAccount from '../../containers/DeleteAccount/DeleteAccount';
import DeleteAccountVerification from '../../containers/DeleteAccount/DeleteAccountVerification';
import DeleteAccountSuccess from '../../containers/DeleteAccount/DeleteAccountSuccess';
import Discover from '../../containers/Discover';
import DownloadHistory from '../../containers/DownloadHistory/DownloadHistory';
import Elections from '../../containers/Elections/Elections';
import ElectionsInternal from '../../containers/Elections/ElectionsInternal';
import ElectionInternalDrillDown from '../../containers/Elections/ElectionInternalDrillDown';
import ElectionsWebView from '../../containers/Elections/ElectionsWebView';
import ElectionsWebViewToken from '../../containers/Elections/ElectionsWebViewToken';
import ElectionsInternalWebView from '../../containers/Elections/ElectionsInternalWebView';
import ElectionInternalDrillDownWebView from '../../containers/Elections/ElectionInternalDrillDownWebView';
import ElectionsLegendWebView from '../../containers/Elections/ElectionsLegendWebView';
import ElectionsLegend from '../../containers/Elections/ElectionsLegend';
import EmailConfirmation from '../../containers/EmailConfirmation';
import Favorites from '../../containers/Favorites';
import ForgotPassword from '../../containers/ForgotPassword';
import ForgotPasswordConfirm from '../../containers/ForgotPasswordConfirm';
import Interest from '../../containers/Interest';
import NavigationLayout from '../../containers/NavigationLayout';
import MyLibrary from '../../containers/MyLibrary';
import MyNews from '../../containers/MyNews';
import MyStats from '../../containers/MyStats/MyStats';
import MyStatsWebView from '../../containers/MyStats/MyStatsWebView';
import MyStatsWebViewToken from '../../containers/MyStats/MyStatsWebViewToken';
import Notifications from '../../containers/Notifications';
import Onboard from '../../containers/Onboard';
import OnboardInterests from '../../containers/Onboard/OnboardInterests';
import Premium from '../../containers/Premium';
import PremiumPurchase from '../../containers/Premium/PremiumPurchase';
import PremiumConfirmation from '../../containers/Premium/PremiumConfirmation';
import ReadArticles from '../../containers/ReadArticles';
import SignedOutLayout from '../../containers/SignedOutLayout';
import SignIn from '../../containers/SignIn';
import SignOut from '../../containers/SignOut';
import SignUp from '../../containers/SignUp';
import SmartRedirectLayout from '../../containers/SmartRedirectLayout/SmartRedirectLayout';
import TopArticles from '../../containers/TopArticles';
import WebViewLayout from '../../containers/WebViewLayout/WebViewLayout';
import Writer from '../../containers/Writer/Writer';
import routes from '../../routes';

export default function MainRouter() {
  const [previousUrl, setPreviousUrl] = useState();
  const location = useLocation();
  useEffect(() => { // eslint-disable-line arrow-body-style
    const historyPaths = ['/discover', '/articles', '/topics', '/writers', '/all-publishers', '/publishers'];
    const hasPaths = historyPaths.some((path) => location?.pathname.includes(path) === true);
    if (!hasPaths) {
      localStorage.removeItem('exploreHistory');
    }

    return () => setPreviousUrl(location);
  }, [location]);
  return (
    <Switch>
      <SignedOutLayout path={routes.auth.signin} exact component={SignIn} />
      <SignedOutLayout path={routes.auth.signup} exact component={SignUp} allowSigned />
      <SignedOutLayout path={routes.auth.forgotPassword} exact component={ForgotPassword} />
      <SignedOutLayout path={routes.auth.forgotPasswordConfirm} exact component={ForgotPasswordConfirm} />
      <SignedOutLayout path={routes.auth.forgotPasswordConfirmLegacy} exact component={ForgotPasswordConfirm} />
      <SignedOutLayout path={routes.auth.deleteAccount} exact component={DeleteAccount} allowSigned />
      <SignedOutLayout path={routes.auth.deleteAccountVerification} exact component={DeleteAccountVerification} allowSigned />
      <SignedOutLayout path={routes.auth.deleteAccountSuccess} exact component={DeleteAccountSuccess} allowSigned />
      <SignedOutLayout path={routes.emailConfirmation} exact component={EmailConfirmation} allowSigned />
      <Route path={routes.auth.signout} exact component={SignOut} />
      <Route path={routes.publishers.main(':slug')} useContainer={false} exact component={() => <Channel previousUrl={previousUrl} />} />
      <NavigationLayout path={routes.publishers.topic(':slug', ':topicId')} useContainer={false} exact component={Channel} />
      <NavigationLayout path={routes.publishers.about(':slug')} useContainer={false} exact component={ChannelAbout} />
      <NavigationLayout path={routes.publishers.categories(':slug')} useContainer={false} exact component={ChannelCategories} />
      <NavigationLayout path={routes.publishers.recent(':slug')} useContainer={false} exact component={ChannelRecent} />
      <NavigationLayout path={routes.publishers.members(':slug')} useContainer={false} exact component={ChannelMembers} />
      <Route path={routes.writers.main(':slug')} useContainer={false} exact component={() => <Writer previousUrl={previousUrl} />} />
      <NavigationLayout path={routes.news.main} exact component={MyNews} />
      <NavigationLayout path={routes.news.feed(':topicId')} useContainer={false} exact component={MyNews} />
      <Route path={routes.interest.feed(':interestId')} useContainer={false} exact component={Interest} />
      <Route path={routes.news.article(':id')} useContainer={false} exact component={() => <Article previousUrl={previousUrl} />} />
      <NavigationLayout path={routes.news.topArticles} exact component={TopArticles} />
      <Route path={routes.news.sharedArticle(':id')} exact component={SharedArticle} />
      <NavigationLayout path={routes.discover} exact component={Discover} />
      <NavigationLayout path={routes.allPublishers} exact component={AllPublishers} />
      <NavigationLayout path={routes.myLibrary} exact component={MyLibrary} />
      <NavigationLayout path={routes.myStats} exact component={MyStats} />
      <Route path={routes.myStatsWebViewToken} exact component={MyStatsWebViewToken} />
      <Route path={routes.myStatsWebView} exact component={MyStatsWebView} />
      <NavigationLayout path={routes.downloadHistory} exact component={DownloadHistory} />
      <NavigationLayout path={routes.bookmarks} exact component={Bookmarks} />
      <NavigationLayout path={routes.favorites} exact component={Favorites} />
      <NavigationLayout path={routes.read} exact component={ReadArticles} />
      <NavigationLayout path={routes.notifications} exact component={Notifications} />
      <Route path={routes.premium} exact component={Premium} />
      <Route path={routes.premiumPurchase(':priceId')} exact component={PremiumPurchase} />
      <NavigationLayout path={routes.premiumConfirmation} exact component={PremiumConfirmation} />
      <Redirect exact from="/" to={routes.news.main} />
      <Route path={routes.onboard.main} exact component={Onboard} />
      <Route path={routes.onboard.interests} exact component={OnboardInterests} />
      <Route path={routes.app.getApp} exact component={AppRedirect} />
      <NavigationLayout path={routes.contestsDetail(':contestId')} exact component={ContestInternal} />
      <NavigationLayout path={routes.contests} exact component={Contests} />
      <Route path={routes.contestsWebViewToken} exact component={ContestsWebViewToken} />
      <Route path={routes.contestsWebView} exact component={ContestsWebView} />
      <Route path={routes.contestsDetailWebView(':contestId')} exact component={ContestInternalWebView} />
      <SmartRedirectLayout path={routes.elections} exact component={Elections} />
      <SmartRedirectLayout path={routes.electionsLegend} exact component={ElectionsLegend} />
      <WebViewLayout path={routes.electionsLegendWebView} exact component={ElectionsLegendWebView} />
      <SmartRedirectLayout path={routes.electionsInternal(':type')} exact component={ElectionsInternal} />
      <SmartRedirectLayout path={routes.electionsInternalDrillDown(':type', ':party')} exact component={ElectionInternalDrillDown} />
      <Route path={routes.electionsWebViewToken} exact component={ElectionsWebViewToken} />
      <WebViewLayout path={routes.electionsWebView} exact component={ElectionsWebView} />
      <WebViewLayout path={routes.electionsInternalWebView(':type')} exact component={ElectionsInternalWebView} />
      <WebViewLayout path={routes.electionsInternalDrillDownWebView(':type', ':party')} exact component={ElectionInternalDrillDownWebView} />
    </Switch>
  );
}
